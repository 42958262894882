import { NavLink } from '@remix-run/react';
import { useState } from 'react';

import { PageLoadingBar } from '../../../../../app/components/PageLoadingBar';
import { useAppAnalytics } from '../../../../analytics/app/identifiable';
import { OrganizerRoleUtils } from '../../../../types';
import { LearningHeaderAvatar } from '../../../HeaderAvatar';
import { DocumentIcon } from '../../../icons/DocumentIcon';
import { HouseIcon } from '../../../icons/HouseIcon';
import { MenuIcon } from '../../../icons/MenuIcon';
import { ScoreboardIcon } from '../../../icons/ScoreboardIcon';
import { StackedSquaresIcon } from '../../../icons/StackedSquaresIcon';
import { XIcon } from '../../../icons/XIcon';
import { LunaParkMenuContainer } from '../../../PublicHome/LunaParkLogoMenu';
import { useUser } from '../../../UserContext';

export function LearningLayout({
  secondaryNav,
  children,
  backgroundClassName,
}: {
  secondaryNav?: React.ReactNode;
  children: React.ReactNode;
  backgroundClassName?: string;
}) {
  const user = useUser();
  const isAdminOrOrgOwner = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <div className='w-full h-full flex flex-col'>
      <PageLoadingBar color='#f11946' />

      {/* Sticky top bar */}
      <div className='sticky top-0 z-40 w-full min-h-[3.75rem] bg-black text-white border-b border-secondary flex items-center justify-between px-4 md:pl-10 md:pr-5'>
        <div className='flex items-center gap-4 md:gap-10'>
          <LunaParkMenuContainer isAdmin={isAdminOrOrgOwner} />
        </div>

        <div className='flex items-center gap-3'>
          {/* Desktop nav */}
          <div className='hidden md:flex items-center gap-8'>
            {isAdminOrOrgOwner ? (
              <>
                <NavItem
                  to='/learning/admin'
                  title='Home'
                  iconActiveColor='text-[#FBB707]'
                  end
                >
                  <div className='flex items-center gap-2'>
                    <HouseIcon />
                    <span className='text-md text-white'>Home</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/my-courses'
                  title='My Courses'
                  iconActiveColor='text-[#E96B24]'
                >
                  <div className='flex items-center gap-2'>
                    <StackedSquaresIcon />
                    <span className='text-md text-white'>My Courses</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/analytics'
                  title='Analytics'
                  iconActiveColor='text-[#8C6FFF]'
                >
                  <div className='flex items-center gap-2'>
                    <ScoreboardIcon className='fill-current w-5 h-5' />
                    <span className='text-md text-white'>Analytics</span>
                  </div>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem
                  to='/learning'
                  title='Assigned to Me'
                  iconActiveColor='text-[#00D0C4]'
                  end
                >
                  <div className='flex items-center gap-2'>
                    <DocumentIcon />
                    <span className='text-md text-white'>Assigned To Me</span>
                  </div>
                </NavItem>
              </>
            )}
          </div>

          {/* Desktop Avatar */}
          <div className='hidden md:block ml-8'>
            <LearningHeaderAvatar />
          </div>

          {/* Mobile burger menu toggler */}
          <button
            className='md:hidden p-2'
            type='button'
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            aria-label='Toggle Navigation Menu'
          >
            {isMobileNavOpen ? <XIcon /> : <MenuIcon />}
          </button>
        </div>
      </div>

      {/* Mobile Nav items */}
      {isMobileNavOpen && (
        <div
          className='
            md:hidden
            fixed           
            top-[3.75rem]  
            left-0
            right-0
            z-50           
            bg-black
            border-b border-secondary
            text-white
            shadow-lg
          '
        >
          <div className='flex flex-col gap-3 px-4 py-3'>
            {isAdminOrOrgOwner && (
              <>
                <NavItem
                  to='/learning/admin'
                  title='Home'
                  end
                  onClick={() => setIsMobileNavOpen(false)}
                  iconActiveColor='text-[#FBB707]'
                >
                  <div className='flex items-center gap-2'>
                    <HouseIcon />
                    <span className='text-md text-white'>Home</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/my-courses'
                  title='My Courses'
                  onClick={() => setIsMobileNavOpen(false)}
                  iconActiveColor='text-[#E96B24]'
                >
                  <div className='flex items-center gap-2'>
                    <StackedSquaresIcon />
                    <span className='text-md text-white'>My Courses</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/analytics'
                  title='Analytics'
                  onClick={() => setIsMobileNavOpen(false)}
                  iconActiveColor='text-[#8C6FFF]'
                >
                  <div className='flex items-center gap-2'>
                    <ScoreboardIcon className='fill-current w-5 h-5' />
                    <span className='text-md text-white'>Analytics</span>
                  </div>
                </NavItem>
              </>
            )}
            <NavItem
              to='/learning'
              title='Assigned to Me'
              onClick={() => setIsMobileNavOpen(false)}
              iconActiveColor='text-[#00D0C4]'
              end
            >
              <div className='flex items-center gap-2'>
                <DocumentIcon />
                <span className='text-md text-white'>Assigned To Me</span>
              </div>
            </NavItem>
          </div>
        </div>
      )}

      {secondaryNav}

      {/* Main content area */}
      <div
        className={`
          w-full flex-1
          ${backgroundClassName ?? 'bg-modal'}
          text-white
          overflow-auto
          scrollbar
        `}
      >
        {children}
      </div>
    </div>
  );
}

function NavItem(props: {
  title: string;
  to: string;
  children?: React.ReactNode;
  onClick?: () => void;
  end?: boolean;
  iconActiveColor: string;
}) {
  const appAnalytics = useAppAnalytics();

  const handleClick = () => {
    appAnalytics.trackNavItemClicked(props.title, props.to);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `text-sm tracking-[1.25px] ${
          isActive
            ? `font-bold ${props.iconActiveColor}`
            : 'font-medium text-white'
        }`
      }
      onClick={handleClick}
      end={props.end}
    >
      {props.children || props.title}
    </NavLink>
  );
}
